<template>
    <div class="home">
      <div class="pic">
        <div class="input-backgroud" style="">
          <div style="display: flex;padding: 5%" >
            <el-input v-model="input" placeholder="输入运单号快速查单" style="margin-right: 5%"></el-input>
            <el-button  size="mini" type="primary" @click="getOrderDetail()">查询</el-button>
          </div>
          <img
              v-show="isRoute"
              style="object-fit: cover;"
              src="../assets/img/kong.png"
              alt
          />
          <div style="overflow: auto" v-show="!isRoute">
            <div class="block" style="text-align: left;padding-left:30%;overflow-y: scroll;height: 200px;background-color: white;position: relative;">
              <el-timeline>
                <el-timeline-item
                    placement="top"
                    v-for="(activity, index) in route"
                    :key="index"
                    :icon="activity.icon"
                    :type="activity.type"
                    :color="activity.color"
                    :size="activity.size"
                    :timestamp="todateTimelocal(activity.createTime)"
                    @click.native="toDeatil(activity)"
                >
                  {{activity.routeDescription}}
                  <p style="position: absolute;top:0;left:-32%">{{activity.routeNode}}</p>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
          <div>

          </div>
        </div>
      </div>
      <PromotionDialog ref="promotionDialog"></PromotionDialog>
      <div style="width: 100%;height: 70px;background: #2f7bff;color:white;display: flex;align-items: center;justify-content: center;">

            <div style="margin-right: 10%;display:flex;">
              <el-popover
                  placement="top"
                  title=""
                  width="200"
                  trigger="hover"
                  >
                <div style="width:200px;height:200px;text-align: center">
                  <img
                      style="width:100%;height:100%;object-fit: cover;"
                      src="../assets/img/gzhma.jpg"
                      alt
                  />
                </div>
                <div  slot="reference" style="display: flex;line-height: 34px;align-items: center">
                  <img src="../assets/img/gzh.png" style="padding-right: 10px;height: 34px" class="change"/>
                  <div style="" >公众号</div>
                </div>
              </el-popover>
            </div>
        |
          <div style="display:flex;margin-left:10%;">
            <el-popover
                placement="top"
                title=""
                width="200"
                trigger="hover"
                >
              <div style="width:200px;height:200px;text-align: center">
                <img
                    style="width:100%;height:100%;object-fit: cover;"
                    src="../assets/img/xcxma.jpg"
                    alt
                />
              </div>
              <div  slot="reference" style="display: flex;line-height: 34px;align-items: center">
                <img src="../assets/img/xcx.png"  style="padding-right: 10px"/>
                <p style="line-height: 34px">小程序</p>
              </div>
              </el-popover>
          </div>
<!--        |-->
<!--          <div style="margin-left: 10%;display:flex;">-->
<!--&lt;!&ndash;            <el-popover&ndash;&gt;-->
<!--&lt;!&ndash;                placement="top-start"&ndash;&gt;-->
<!--&lt;!&ndash;                title=""&ndash;&gt;-->
<!--&lt;!&ndash;                width="200"&ndash;&gt;-->
<!--&lt;!&ndash;                trigger="hover"&ndash;&gt;-->
<!--&lt;!&ndash;                content="">&ndash;&gt;-->
<!--              <div   style="display: flex;line-height: 34px;align-items: center">-->
<!--                <img src="../assets/img/app.png"  style="padding-right: 10px"/>-->
<!--                <p style="line-height: 34px">商城</p>-->
<!--              </div>-->
<!--&lt;!&ndash;              </el-popover>&ndash;&gt;-->
<!--          </div>-->
      </div>

      <div style="font-size: 30px;margin:30px auto;color: #575757;line-height: 30px;text-align: center;">
        服务介绍
      </div>

      <div style="display: flex;justify-content: center;margin-bottom: 40px;">
        <el-card  class="box-card" @click.native="toDeliveryOrder">
          <img src="../assets/img/xdth.png"/>
          <p style="font-size: 20px;margin:10px auto 40px auto;font-weight: bold;">下单提货</p>
          <p>在线下单</p>
          <p>方便快捷</p>
          <p>价格优惠</p>
          <p>品质服务</p>
        </el-card>

        <el-card class="box-card" @click.native="toMyOrderList">
          <img src="../assets/img/wdyd.png"/>
          <p style="font-size: 20px;margin:10px auto 40px auto;font-weight: bold;">我的运单</p>
          <p>运单统计</p>
          <p>运单详情</p>
          <p>发货运单</p>
          <p>收货运单</p>
        </el-card>

        <el-card class="box-card" @click.native="toSelfHelpLoan">
          <img src="../assets/img/zzfk.png"/>
          <p style="font-size: 20px;margin:10px auto 40px auto;font-weight: bold;">自助放款</p>
          <p>线上申请</p>
          <p>自助选择</p>
          <p>自助提现</p>
        </el-card>

        <el-card class="box-card" @click.native="toChangeInformation">
          <img src="../assets/img/xgxx.png"/>
          <p style="font-size: 20px;margin:10px auto 40px auto;font-weight: bold;">修改信息</p>
          <p>用户信息</p>
          <p>手机号</p>
          <p>银行卡</p>
        </el-card>

        <el-card class="box-card1">
          <p style="font-size: 20px;text-align: center;color: #333333;line-height: 20px;">联系我们</p>
          <p style="width: 23px;height: 5px;background: #2f7bff;border-radius: 3px;margin: 5px auto"></p>
          <p style="margin-top:20px;line-height: 25px;">22年的稳步发展与积累，为长吉布局全国网络奠定了坚实基础;长吉秉承“让客户生意更好，让人才实现梦想”的企业使命。始终坚持“合作第一、组织为大、用服务说话"的企业价值观，倡导企业员工用实际行动践行"一切为了客户我愿意!”的企业口号，全力以赴向成为“物流新生态领航者"的企业愿景奋力前行。</p>
          <div style="color:white;position: absolute;left: 0;bottom: 0;width: 100%;height: 30px;line-height:30px;background: #2f7bff;">
            <p style="float:left;padding-left: 20%">全国客服电话:</p>
            <p style="float:right;padding-right: 20%">4006175757</p>
          </div>
        </el-card>
      </div>

      <div style="width: 100%;text-align: center;height: 5vh;font-size: 12px;line-height: 5vh">
          Copyright © 2024.Company name All rights reserved.
          辽ICP备20008612号
      </div>
    </div>

</template>

<script>
import PromotionDialog from '@/components/PromotionDialog.vue';
import { findOrderId } from "@/api/orderInfo";
    export default {
        name: "HelloWorld",
        components: {
          PromotionDialog,
        },
        data() {
            return {
                isRoute:true,
                input:'',//运单号
                route:[],
                orderId:'',//运单id
                data:[],
            };
        },
        created() {

        },
        computed: {

        },
        methods: {
          //调用PromotionDialog.vue里面的selectPromotion方法
          showPromotionDialog() {
            this.$refs.promotionDialog.selectPromotion()
          },
          //运单详情
          toDeatil(){
            this.$router.push({ name: 'orderInfo' ,params: { data: this.data }})
          },
          //查询运单
          getOrderDetail(){
            findOrderId({id:this.input}).then((res) => {
              if (res.code === 200) {
                if(res.data!=null){
                  this.data = res.data;
                  this.route = res.data.route;
                  this.isRoute = false;
                }
              }
            })
          },
          //时间戳转日期＋时间
          todateTimelocal(timeStamp) {
            if (timeStamp === '' || timeStamp === null || timeStamp === undefined || timeStamp === '0' || timeStamp === 0) return '';
            var date = new Date();
            date.setTime(timeStamp);
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            var second = date.getSeconds();
            minute = minute < 10 ? ('0' + minute) : minute;
            second = second < 10 ? ('0' + second) : second;
            return timeStamp = y + '-' + m + '-' + d + '   ' + h + ':' + minute + ':' + second;
          },
          toDeliveryOrder(){
            if(!JSON.parse(localStorage.getItem("userObj"))){
              this.$message({
                message: "未登录，请先登录",
                type: "error",
                offset: 80,
              });
              this.showPromotionDialog()
            }else{
              this.$router.push({ name: 'deliveryOrder'})
            }
          },
          toMyOrderList(){
            if(!JSON.parse(localStorage.getItem("userObj"))){
              this.$message({
                message: "未登录，请先登录",
                type: "error",
                offset: 80,
              });
              this.showPromotionDialog()
            }else{
              this.$router.push({ name: 'myOrderList'})
            }
          },
          toSelfHelpLoan(){
            if(!JSON.parse(localStorage.getItem("userObj"))){
              this.$message({
                message: "未登录，请先登录",
                type: "error",
                offset: 80,
              });
              this.showPromotionDialog()
            }else{
              this.$router.push({ name: 'selfHelpLoan'})
            }
          },
          toChangeInformation(){
            if(!JSON.parse(localStorage.getItem("userObj"))){
              this.$message({
                message: "未登录，请先登录",
                type: "error",
                offset: 80,
              });
              this.showPromotionDialog()
            }else{
              this.$router.push({ name: 'changeInformation'})
            }
          },
        },
        mounted() {

        },
        destroyed() {

        },
    };
</script>

<style lang="scss" scoped>
  /deep/.el-card__body, .el-main {
    padding: 30px!important;
  }
.home{
  padding: 0;
  margin: 0;
}
.input-backgroud{
  position: absolute;
  left:18%;
  top:12%;
  background-position:center;
  background-size:180px 120px;
  background-image: url("../assets/img/kong.png");
  text-align: center;
  width: 300px;height: 300px;
  background-color: #ffffff;border-radius: 8px;
  background-repeat: no-repeat;
}
.box-card{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: center;
  /*width: 10%;*/
  margin-right: 3%;
  p{
    margin: 20px auto;
    font-size: 16px;
    color: #333333;
    line-height: 16px;
  }
}
.box-card1{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 20%;
  padding: 20px;
  position: relative;
}
.pic{
  width: 100%;
  background-image: url("../assets/img/banner.png");
  //background-repeat: no-repeat;
  height: 450px;
  background-size:cover;
  //padding: 50px 200px;
}
</style>
