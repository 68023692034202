import request from "../utils/request";

//订单详情
export function findOrderDetail(params) {
    return request({
        url: "myOrder/findOrderDetail",
        method: "post",
        params: params,
    });
}
//取消订单
export function updateOrder(params) {
    return request({
        url: "myOrder/updateOrder",
        method: "post",
        params: params,
    });
}
//查询我的运单详情
export function findOrderId(params) {
    return request({
        url: "myWayBill/findOrderId",
        method: "post",
        params: params,
    });
}